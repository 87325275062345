<template>
    <div>

        <b-card no-body class="mb-5">
            <b-card-header header-tab="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
                    {{ $t('advanced_filter')}}
                </b-button>
            </b-card-header>

            <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                    <div class="row mb-5">
                        <div class="form-group col-md-4 mt-2 mb-2">
                            <label for="supplier_id">{{$t('supplier')}}</label>
                            <multiselect v-model="supplier"
                                :placeholder="$t('supplier')"
                                label="full_name"
                                track-by="id"
                                :options="suppliers"
                                :multiple="false"
                                :taggable="true"
                                :show-labels="false"
                                :show-no-options="false"
                                :show-no-results="false">
                            </multiselect>
                        </div>
                        <div class="form-group col-md-4 mt-2 mb-2">
                            <label for="brand_id">{{$t('brand')}}</label>
                            <multiselect 
                                id="brand_id" 
                                v-model="brand_id" 
                                :placeholder="$t('items.brand')"
                                label="name"
                                track-by="id"
                                :options="brands"
                                :multiple="false"
                                :taggable="true"
                                :show-labels="false"
                                :show-no-options="false"
                                :show-no-results="false">
                            </multiselect>
                        </div>
                        <div class="form-group col-md-4 mt-2 mb-2">
                            <label for="order_by">{{$t('stocks_report.order_by')}}</label>
                            <select name="" id="order_by" v-model="filters.order_by" class="custom-select">
                                <option value="product_name">{{ $t('stocks_report.product_name') }}</option>
                                <option value="stock_level_descending">{{ $t('stocks_report.stock_level_descending') }}</option>
                                <option value="stock_level_ascending">{{ $t('stocks_report.stock_level_ascending') }}</option>
                            </select>
                        </div>
                        <div class="form-group col-md-4 mt-2 mb-2">
                            <label>{{$t('items.category')}}</label>
                            <treeselect
                                    :options="categories"
                                    :load-options="loadOptions"
                                    :multiple="false"
                                    :value="filters.category_id"
                                    @input="updateValue"
                                    :searchable="true"
                                    :show-count="true"
                                    :no-children-text="$t('No_sub_options')"
                                    :no-options-text="$t('No_options_available')"
                                    :no-results-text="$t('No_results_found')"
                                    :placeholder="$t('Select')">
                            </treeselect>
                        </div>

                        <div class="form-group col-md-4 mt-2 mb-2">
                            <label for="f_status">{{$t('stocks_report.warehouse')}}</label>
                            <multiselect v-model="inventory"
                                :placeholder="$t('stocks_report.warehouse')"
                                label="name"
                                track-by="id"
                                :options="inventories"
                                :multiple="false"
                                :taggable="true"
                                :show-labels="false"
                                :show-no-options="false"
                                :show-no-results="false">
                            </multiselect>
                        </div>

                        <div class="form-group col-md-4 mt-2 mb-2">
                            <label for="order_by">{{$t('estimated_inventory.calculate_using')}}</label>
                            <select name="" id="calculate_using" v-model="filters.calculate_using" class="custom-select">
                                <option value="average_buy_price">{{ $t('estimated_inventory.average_buy_price') }}</option>
                                <option value="buy_price">{{ $t('estimated_inventory.buy_price') }}</option>
                            </select>
                        </div>

                        <div class="form-group col-md-4 mt-2 mb-2">
                            <label for="from_date">{{$t('inventory_transaction_report.from_date')}}</label>
                            <input type="date" id="from_date" v-model="filters.from_date" class="form-control">
                        </div>

                        <div class="form-group col-md-4 mt-2 mb-2">
                            <label for="to_date">{{$t('inventory_transaction_report.to_date')}}</label>
                            <input type="date" id="to_date" v-model="filters.to_date" class="form-control">
                        </div>


                        <div class="form-group d-inline-flex col-md-4 mt-10">
                            <b-button class="mt-auto mr-2" variant="primary" @click="doFilter"> {{$t('display_report')}}</b-button>
                            <b-button class="mt-auto mr-2" variant="danger" @click="resetFilter"><i class="fas fa-trash-restore"></i> {{$t('reset_search')}}</b-button>

                        </div>
                    </div>
                </b-card-body>
            </b-collapse>
        </b-card>
        

        <div class="card card-custom mt-5">

            <div class="card-body">

                <!-- Filter -->
                <div>
                    <div class="m-form m-form--fit m--margin-bottom-20">
                        <div class="row">
                            <!-- <div class="col-12 mt-10">
                                <hr>
                            </div> -->
                            <div class="col-12 mt-10" id="summaryTableTitle">
                                <h4 class="text-center">{{ $t('estimated_inventory.estimated_inventory')}}</h4>
                                <h5 class="text-center"><span v-if="filters.from_date">{{ $t('from') }} {{filters.from_date}}</span> <span v-if="filters.to_date">{{ $t('to') }} {{filters.to_date}}</span></h5>
                            </div>
                            <div  class="col-12 mt-10 d-flex justify-content-end">
                                <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                                    <download-excel
                                            class="dropdown-item"
                                            :fetch="dataReport"
                                            :fields="json_fields"
                                            :name="$t('estimated_inventory.estimated_inventory')+'.xls'">
                                        <i class="la la-file-excel"></i>{{$t('excel')}}
                                    </download-excel>

                                    <button class="dropdown-item" @click="printData('summaryTable')">
                                        <span><i class="fa fa-print"></i> <span>{{$t('print')}}</span></span>
                                    </button>
                                </b-dropdown>
                            </div>
                            <div class="col-12 mt-10">
                                <div class="table-responsive">
                                    <table class="table table-bordered text-center" id="summaryTable">
                                        <thead>
                                        <!-- <tr>
                                            <th></th>
                                            <th colspan="5">{{$t('inventory_transaction_report.inward')}}</th>
                                            <th colspan="5">{{$t('inventory_transaction_report.outward')}}</th>
                                            <th></th>
                                        </tr> -->
                                        <tr>
                                            <th>{{$t('estimated_inventory.code')}}</th>
                                            <th>{{$t('estimated_inventory.product_name')}}</th>
                                            <th>{{$t('estimated_inventory.qty')}}</th>
                                            <th>{{$t('estimated_inventory.current_retail_price')}}</th>
                                            <th>{{$t('estimated_inventory.average_retail_price')}}</th>
                                            <th>{{$t('estimated_inventory.expected_total_retail_price')}}</th>
                                            <th>{{$t('estimated_inventory.total_buy_price')}}</th>
                                            <th>{{$t('estimated_inventory.expected_profit')}}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(row, index) in data.data" :key="index">
                                            <td>{{row.code}}</td>
                                            <td>{{row.product_name}}</td>
                                            <td>{{row.qty}}</td>
                                            <td>{{row.current_retail_price}}</td>
                                            <td>{{row.average_retail_price}}</td>
                                            <td>{{row.expected_total_retail_price}}</td>
                                            <td>{{row.total_buy_price}}</td>
                                            <td>{{row.expected_profit}}</td>
                                        </tr>
                                        </tbody>
                                        <tbody>
                                        <tr v-if="data.total">
                                            <td>{{$t('total')}}</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>{{data.total.sum_expected_total_retail_price}}</td>
                                            <td>{{data.total.sum_total_buy_price}}</td>
                                            <td>{{data.total.sum_expected_profit}}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-12 text-center" v-if="page">
                                    <button class="btn btn-warning" @click="loadMore">
                                        <p class="mb-0"><b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon> {{$t('load_more')}}</p>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- End Filter -->

            </div>
        </div>

    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    export default {
        name: "index-inventory-transaction-report",
        data() {
            return {
                mainRoute: '/reports/estimated-inventory-report',
                mainRouteDependency: 'base/dependency',
                suppliers: [],
                inventories: [],
                data: [],
                brands: [],
                categories: [],

                filters: {
                    supplier_id: null,
                    brand_id: null,
                    order_by: null,
                    category_id: null,
                    inventory_id: null,
                    calculate_using: null,
                    from_date: null,
                    to_date: null,
                },
                brand_id: null,
                item: null,
                supplier: null,
                inventory : null,
                json_meta: [
                    [{
                        key: "charset",
                        value: "utf-8",
                    },],
                ],
                page:0,
            }
        },
        watch: {
            brand_id: function (val) {
                if (val) {
                    this.filters.brand_id = val.id;
                } else {
                    this.filters.brand_id = null;
                }
            },
            supplier: function (val) {
                if (val) {
                    this.filters.supplier_id = val.id;
                } else {
                    this.filters.supplier_id = null;
                }
            },
            inventory: function (val) {
                if (val && val.id) {
                    this.filters.inventory_id = val.id;
                } else {
                    this.filters.inventory_id = null;
                }
            }
        },
        computed: {

            json_fields: function () {
                let fields = {};
                fields[this.$t('estimated_inventory.code')] = 'code';
                fields[this.$t('estimated_inventory.product_name')] = 'product_name';
                fields[this.$t('estimated_inventory.qty')] = 'qty';
                fields[this.$t('estimated_inventory.current_retail_price')] = 'current_retail_price';
                fields[this.$t('estimated_inventory.average_retail_price')] = 'average_retail_price';
                fields[this.$t('estimated_inventory.expected_total_retail_price')] = 'expected_total_retail_price';
                fields[this.$t('estimated_inventory.total_buy_price')] = 'total_buy_price';
                fields[this.$t('estimated_inventory.expected_profit')] = 'expected_profit';

                return fields;
            },
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.store_reports"),route:'/store/reports'}, {title: this.$t("MENU.estimated_inventory")}]);

            this.getSuppliers();
            this.getBrands();
            this.getCategories();
            this.getInventories();
        },
        methods: {

            loadMore() {
                this.page = this.page? (this.page + 1) : 1;
                this.getData();
            },
            doFilter() {
                this.page = 1;
                this.getData();
            },
            resetFilter() {

                this.filters.supplier_id = null,
                    this.filters.brand_id = null,
                    this.brand_id = null,
                    this.supplier = null,
                    this.inventory = null,
                    this.filters.order_by = null,
                    this.filters.category_id = null,
                    this.filters.inventory_id = null,
                    this.filters.from_date = null,
                    this.filters.calculate_using = null,
                    this.filters.to_date = null,

                    this.getData();

            },
            dataReport() {
                this.setReportLog('excel', 'estimated inventory');
                return this.data;
            },
            getData() {
                ApiService.get(this.mainRoute, {params: {...this.filters,page:(this.page? this.page : 1)}}).then((response) => {
                    this.data = response.data.data;
                });
            },
            getSuppliers() {
                ApiService.get(this.mainRouteDependency + "/suppliers").then((response) => {
                    this.suppliers = response.data.data;
                });
            },
            updateValue(value) {
                this.filters.category_id = value
            },
            loadOptions() {
            },
            getCategories() {
                ApiService.get(this.mainRouteDependency + "/categories").then((response) => {
                    this.categories = response.data.data;
                });
            },
            getBrands() {
                ApiService.get(this.mainRouteDependency + "/brands").then((response) => {
                    this.brands = response.data.data;
                });
            },
            getInventories() {
                ApiService.get(this.mainRouteDependency + "/inventories").then((response) => {
                    this.inventories = response.data.data;
                });
            },

            printData(tableId) {
                this.setReportLog('pdf', 'estimated inventory');
                let divToPrint = document.getElementById(tableId);
                let divTitleToPrint = document.getElementById(tableId + 'Title');
                let newWin = window.open("");
                newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>" + divTitleToPrint.outerHTML + divToPrint.outerHTML);
                setTimeout(() => {
                    newWin.print();
                    newWin.close();
                }, 100)
            },
            /**
             * register log
             */
            setReportLog(key, description){
                ApiService.post("reports/system_activity_log/log_type",{
                    key:key,
                    description: description,
                });
            }
        },
    };
</script>
